var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"l-gutters"},[_c('div',{staticClass:"l-container"},[_c('v-breadcrumbs',{staticClass:"mt-11 pl-0 pb-2 text-uppercase font-weight-bold"},[_c('v-breadcrumbs-item',{staticClass:"text--black"},[_c('router-link',{staticClass:"text-decoration-none text--black",attrs:{"to":{ name: 'creativecentre.root' }}},[_vm._v(" Creative Centre ")])],1),_c('v-breadcrumbs-item',{staticClass:"px-1"},[_c('v-icon',{attrs:{"color":"black","size":"26"}},[_vm._v("mdi-chevron-right")])],1),_c('v-breadcrumbs-item',{staticClass:"current-page-breadcrumb"},[_vm._v(" "+_vm._s(_vm.currentCampaignCategory.title)+" Campaigns ")])],1),_c('h1',{staticClass:"category-title"},[_vm._v(" "+_vm._s(_vm.currentCampaignCategory.title)+" campaigns ")]),_vm._l((_vm.campaigns.data.subcategories),function(subCategory){return _c('div',{key:subCategory.id,staticClass:"mb-8"},[_c('h3',{staticClass:"subcategory-title font-weight-bold my-4"},[_vm._v(" "+_vm._s(subCategory.title)+" ")]),_c('v-row',{attrs:{"sm":"6","md":"4"}},_vm._l((subCategory.campaigns),function(campaign){return _c('v-col',{key:campaign.id,attrs:{"sm":"6","md":"4"}},[_c('router-link',{staticClass:"text-decoration-none d-block",attrs:{"to":{
              name: 'creativecentre.campaignDetail',
              params: {
                category: _vm.currentCampaignCategory.id,
                id: campaign.id,
              },
            }}},[_c('campaign-card',{attrs:{"campaign":campaign}})],1)],1)}),1)],1)}),(_vm.currentCampaignCategory)?_c('campaign-sidebar',{attrs:{"campaign-category-id":_vm.currentCampaignCategory.id}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import CampaignSidebar from '@/components/campaign-sidebar.vue';
import { Route } from 'vue-router';
import { CampaignModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import AssetCard from '@/components/cards/assetCard.vue';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import CampaignCard from '@/components/cards/campaignCard.vue';
import { AltDrawerType } from '@/constants';

const campaignModule = getModule(CampaignModule);

@Component({
  components: { AssetCard, CampaignSidebar, CampaignCard },
})
export default class CampaignCategoryIndex extends LoggedInComponentBase {
  get currentCampaignCategory(): CampaignCategory {
    return campaignModule.currentCampaignCategory;
  }

  get campaigns() {
    return campaignModule.campaignsByCampaignCategory;
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: CampaignCategoryIndex) => void)) => void
  ): Promise<void> {
    try {
      await campaignModule.getCampaignCategories();
      campaignModule.setCurrentCampaignCategory(to.params.category);
      next();
    } catch (err) {
      console.error(err.message);
      next(false);
    }
  }

  async created() {
    await campaignModule.getCampaignsByCampaignCategory({
      categoryId: this.$route.params.category,
      state: this.currentStore ? this.currentStore.state : '',
    });
  }

  mounted() {
    this.updateSidebarStates();
  }

  public updateSidebarStates() {
    if (this.$vuetify.breakpoint.smAndDown) {
      if (this.uiModule.campaignSidebarOpen) {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.CampaignSidebar,
          open: false,
        });
      }

      this.uiModule.setNavigationDrawer(false);
    } else {
      this.uiModule.toggleAltDrawer({
        drawerType: AltDrawerType.CampaignSidebar,
        open: true,
      });
      this.uiModule.setNavigationDrawer(true);
    }
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    await campaignModule.getCampaignsByCampaignCategory({
      categoryId: this.$route.params.category,
      state: this.currentStore ? this.currentStore.state : '',
    });
  }
}
